<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {postService} from "@/services/post.service";
import vueDropzone from "vue2-dropzone";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Articles",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { ckeditor: CKEditor.component, vueDropzone, Layout, PageHeader },
  data() {
    return {
      editing: false,
      posts: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      },
      post: {},
      title: "Articles",
      items: [
        {
          text: "Accueil",
          href: "/"
        },
        {
          text: "Arcticles",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "title",
      sortDesc: false,
      fields: [
        { key: "title", label: "Titre", sortable: true },
        { key: "image", label: "Image", sortable: true },
        { key: "miniature", label: "Miniature", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "actions", thStyle: { width: "15%" } },
        { key: "show_details", label: "Contenu", thStyle: { width: "8%" } }
      ],
      submitted: false,
      editor: ClassicEditor,
    };
  },
  validations: {
    post: {
      title: { required },
      image: { required },
      miniature: { required },
      content: { required },
    }
  },
  computed: {
    rows() {
      return this.posts.length;
    }
  },
  mounted() {
    this.getPosts();
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    mapClicked(mouseArgs) {
      this.pharmacy.latitude = mouseArgs.latLng.lat();
      this.pharmacy.longitude = mouseArgs.latLng.lng();
    },
    async formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let result = this.post.id ? await postService.update(this.post.id, this.post) : await postService.create(this.post);
        if (result >= 400) {
          await Swal.fire("Erreur", "Une erreur s'est produite !", "warning");
        } else {
          await Swal.fire(this.post.id ? 'Modification' : 'Enregistrement', "Opération reussie !", "success")
          await this.getPharmacies();
          this.clearForm();
        }
      }
    },
    clearForm() {
      this.submitted = false;
      this.editing = false;
      this.post = {};
    },
    async getPharmacies() {
      this.posts = await postService.get();
    },
    remove(id) {
      Swal.fire({
        title: "Suppression",
        text: `Vous êtes sûr de supprimer ${this.post.title} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non"
      }).then(async result => {
        if (result.value) {
          let result = await postService.remove(id);
          if (result.status === 200) await Swal.fire("Suppression", "Article supprimé avec succès !", "success");
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items">
      <button v-if="!editing" type="button" class="btn btn-success" @click="editing = true">Nouvelle article</button>
    </PageHeader>

    <div v-if="editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nouveau article</h4>
            <p class="card-title-desc">Créer un nouveau article</p>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label" for="title">Titre</label>
                    <input id="title" v-model="post.title" type="text" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.post.title.$error }"/>
                    <div v-if="submitted && $v.post.title.$error" class="invalid-feedback">
                      <span v-if="!$v.post.title.required">Le nom de la pharmacie est obligatoire.</span>
                    </div>
                  </div>
                </div><br>
                <div class="col-md-6">
                  <div class="mb-2">
                    <h4 class="card-title">Miniature</h4>
                    <p class="card-title-desc mb-2">Charger l'image miniature de l'article</p>
                    <div class="mb-2">
                      <vue-dropzone id="minDropzone" ref="miniatureVueDropzone" :options="dropzoneOptions"></vue-dropzone>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-2">
                    <h4 class="card-title">Image</h4>
                    <p class="card-title-desc mb-2">Charger l'image mise en avant de l'article</p>
                    <div class="mb-2">
                      <vue-dropzone id="imgDropzone" ref="imageVueDropzone" :options="dropzoneOptions"></vue-dropzone>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="mb-3">
                    <label class="form-label" for="address">Contenu</label>
                    <ckeditor v-model="post.content" :editor="editor" :class="{ 'is-invalid': submitted && $v.post.content.$error }"></ckeditor>
                    <div v-if="submitted && $v.post.content.$error" class="invalid-feedback">
                      <span v-if="!$v.post.content.required">Le contenu est obligatoire.</span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary me-3" type="submit">Enregistrer</button>
              <button class="btn btn-secondary" type="reset" @click.prevent="clearForm">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste des articles</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Affiche
                    <b-form-select class="form-control form-control-sm form-select form-select-sm ms-1 mx-1" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher..."
                        class="form-control form-control-sm ml-2 ms-1"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                  :items="posts"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template #cell(actions)="row">
                  <b-button size="sm" class="bg-warning mx-2" @click="post = row.item; editing = true">
                    Modifier
                  </b-button>
                  <b-button size="sm" class="bg-danger" @click="remove(row.item.id)">
                    Supprimer
                  </b-button>
                </template>

                <template #cell(show_details)="row">
                  <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? 'Cacher' : 'Afficher'}}
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card class="mb-0">
                    <b-row class="mb-3">
                      <b-col sm="12" md="6">
                        <div v-html="row.item.content"></div>
                      </b-col>
                    </b-row>
                    <b-button size="sm" @click="row.toggleDetails">Cacher</b-button>
                  </b-card>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

