import errorHandler from "@/services/handler/erroHandler";
import axiosInstance from "@/services/manager/manager.service";

export const postService = {
    get,
    create,
    update,
    remove
};

async function get(){
    try {
        let rets = await axiosInstance.get(`/posts`);
        if (rets) {
            return rets.data;
        }
        return 400;
    } catch (error) {
        return errorHandler(error);
    }
}

async function create(post) {
    try {
        let ret = await axiosInstance.post(`/posts`, post);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function update(id, data) {
    try {
        let ret = await axiosInstance.put(`/posts/${id}`, data);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function remove(id) {
    try {
        return await axiosInstance.delete(`/posts/${id}`);
    } catch (error) {
        return errorHandler(error);
    }
}



